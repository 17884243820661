
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onUpdated,
  computed,
} from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdwon from "@/views/document-center/billing-verification/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiDocumentCenter } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  commonExportFile,
  commonPreviewHtml,
  formatUtcDate,
  formatUtcRangeDate,
  setModuleBCN,
  settlementLastMonthDate,
  settlementLastWeekDate,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { PaymentConfirmationTable } from "@/core/directive/interface/paymentConfirmation";
import { checkPermissionFlag } from "@/directives/permission";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "document-center-payment-verification",
  components: {
    MBDatatable,
    FilterDropdwon,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const filterRef = ref();
    const tableData = ref<Array<PaymentConfirmationTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const disabledExportLastWeek = ref(false);
    const disabledExportLastMonth = ref(false);
    const checkedPaymentConfirmation = ref<Array<number>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "doc_date", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([
      { field: "status", value: 15 },
    ]);
    const tableHeader = ref([
      {
        name: t("documentCenter.documentNo"),
        key: "doc_number",
        sortable: false,
      },
      {
        name: t("documentCenter.type"),
        key: "doc_type",
        sortable: false,
      },
      {
        name: t("invoice.creditor"),
        key: "creditor_number",
        sortable: false,
      },
      {
        name: t("invoice.debtor"),
        key: "debtor_number",
        sortable: false,
      },
      {
        name: t("invoice.invoiceAmount"),
        key: "grant_total",
        align: "right",
        sortable: false,
      },
      {
        name: t("documentCenter.documentDate"),
        key: "doc_date",
        align: "right",
        sortable: false,
      },
      {
        name: t("documentCenter.status"),
        key: "status",
        align: "left",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      filter_date: "doc_date",
    });

    const lastWeekFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        mode: "last_week",
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastWeekDate({
              format: "YYYY-MM-DD",
            }),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastWeekDate({
              format: "YYYY-MM-DD",
              isStart: false,
            }),
            condition: "lteq",
          },
        ],
      };
    });

    const lastMonthFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        mode: "last_month",
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastMonthDate({
              format: "YYYY-MM-DD",
            }),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastMonthDate({
              format: "YYYY-MM-DD",
              isStart: false,
            }),
            condition: "lteq",
          },
        ],
      };
    });

    const setOtherFilter = computed(() => {
      return [];
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
      };
    });

    const isCanExport = computed((): boolean => {
      return checkPermissionFlag({ auth: ["export"] });
    });

    const getPaymentList = async () => {
      loading.value = true;
      const { data } = await ApiDocumentCenter.getPaymentList(
        setCommonFilter.value
      );
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        tableData.value.forEach((item) => {
          item.downloadDisabled = false;
          item.previewDisabled = false;
        });
        total.value = data.data.total;
      }
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedPaymentConfirmation.value = ids;
    };

    const filterChangeStatus = (type, flag = true) => {
      let params = {};
      if (type === "all") {
        if (flag) {
          disabledExportAll.value = true;
          params = {
            ...setCommonFilter.value,
            mode: "all",
            id: [],
          };
        } else {
          disabledExportAll.value = false;
        }
      } else if (type === "selected") {
        if (flag) {
          if (checkedPaymentConfirmation.value.length > 0) {
            params = {
              ...setCommonFilter.value,
              mode: "all",
              id: checkedPaymentConfirmation.value,
            };
            disabledExportSelected.value = true;
          } else {
            return false;
          }
        } else {
          disabledExportSelected.value = false;
        }
      } else if (type === "lastWeek") {
        if (flag) {
          disabledExportLastWeek.value = true;
          params = {
            ...lastWeekFilter.value,
            id: [],
          };
        } else {
          disabledExportLastWeek.value = false;
        }
      } else if (type === "lastMonth") {
        if (flag) {
          disabledExportLastMonth.value = true;
          params = {
            ...lastMonthFilter.value,
            id: [],
          };
        } else {
          disabledExportLastMonth.value = false;
        }
      }
      if (flag) {
        return params;
      }
    };

    const filesExport = async (type = "all") => {
      let idParams = filterChangeStatus(type);
      const data = await ApiDocumentCenter.exportPayment({
        ...idParams,
      });
      filterChangeStatus(type, false);
      commonExportFile(data);
    };

    const fileExport = async (id, item) => {
      item.downloadDisabled = true;
      const data = await ApiDocumentCenter.exportPayment({
        id: [id],
      });
      item.downloadDisabled = false;
      commonExportFile(data);
    };

    let childWin;
    const filePreviewHtml = async (item) => {
      item.previewDisabled = true;
      const { data } = await ApiDocumentCenter.previewPayment({
        id: item.id,
      });
      item.previewDisabled = false;
      if (data.code === 0) {
        commonPreviewHtml(childWin, item.doc_number, data.data);
      } else {
        showServerErrorMsg(data.data);
      }
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "" && val[item] !== null) {
          // if (item.includes(options.value.filter_date)) {
          //   // const isStart = item.includes("_start") ? true : false;
          //   filterArr.push({
          //     field: options.value.filter_date,
          //     value: val[item][0],
          //     condition: "gteq",
          //   });
          //   filterArr.push({
          //     field: options.value.filter_date,
          //     value: val[item][1],
          //     condition: "lteq",
          //   });
          // }
          if (item.includes(options.value.filter_date)) {
            const isStart = item.includes("_start") ? true : false;
            filterArr.push({
              field: options.value.filter_date,
              value: val[item],
              condition: isStart ? "gteq" : "lteq",
            });
          } else if (item === "doc_configuration_id") {
            const arr: any[] = [];
            val[item].map((li) => {
              const cArr = li.split(",");
              arr.push(...cArr);
            });
            if (arr.length > 0) {
              filterArr.push({
                field: item,
                value: arr,
                condition: "in",
              });
            }
          } else if (item === "status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "in",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      updateList();
    };

    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    const updateList = () => {
      getPaymentList();
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const debounceSearch = _.debounce(updateList, 1000);

    const searchItems = () => {
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    const updateStatus = async (id, status) => {
      loading.value = true;
      const { data } = await ApiDocumentCenter.updatePaymentStatus({
        id: id,
        status: status,
      });
      loading.value = false;
      if (data.code == 0) {
        Swal.fire({
          text: t("common.updateSuccessful"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          getPaymentList();
        });
      } else {
        showServerErrorMsg(data.data);
      }
    };

    const init = () => {
      setModuleBCN(t, route);
      updateList();
    };

    onMounted(() => {
      init();
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatUtcDate,
      formatUtcRangeDate,
      settlementLastWeekDate,
      settlementLastMonthDate,
      loading,
      filterRef,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      options,
      isCanExport,
      disabledExportAll,
      disabledExportSelected,
      disabledExportLastWeek,
      disabledExportLastMonth,
      checkedPaymentConfirmation,
      getPaymentList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      onRowsSelectChange,
      filesExport,
      fileExport,
      filePreviewHtml,
      updateStatus,
    };
  },
});
